import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { MergeScanOperator } from 'rxjs/internal/operators/mergeScan';
import { Comptes } from 'src/app/shared/models/comptes';

@Component({
  selector: 'app-entete-carte',
  templateUrl: './entete-carte.component.html',
  styleUrls: ['./entete-carte.component.scss']
})
export class EnteteCarteComponent implements OnInit {
  comptes: any;
  onecompte;
  voirsolde:false;
  constructor( private store: LocalStoreService,private auth: AuthService) { }

  ngOnInit() {
     this.auth.changeSoldeComte();
     this.auth.currentCompte.subscribe( comptes =>this.comptes = comptes )

  }

  slickInit(e) {
    this.onecompte= this.comptes[0];
    this.store.setItem("carteSelect",this.onecompte);
  }
 
  
  afterChange(e) {
    this.onecompte= this.comptes[e.currentSlide];
    this.store.setItem("carteSelect",this.onecompte);
  }
 voirSolde(val){
  this.voirsolde=val;
 }
}
