import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { User } from 'src/app/shared/models/user';
import { Entite } from 'src/app/shared/models/Entite';
import { Constantes } from 'src/app/shared/constantes';
import { EntitesService } from 'src/app/shared/services/entites.service';
import { TransactionsService } from 'src/app/shared/services/transactions.service';
import { Router } from '@angular/router';
import { Comptes } from 'src/app/shared/models/comptes';

@Component({
  selector: 'app-admin-sidebar-right',
  templateUrl: './admin-sidebar-right.component.html',
  styleUrls: ['./admin-sidebar-right.component.scss']
})
export class AdminSidebarRightComponent implements OnInit {
  sidenavOpen: boolean;
  currentUser: User;
  entite: Entite;
  comptes:Comptes;

  transactions = [];

  constructor( public navService: NavigationService,
     private _authService:AuthService,
     private _entitesService:EntitesService,
     private router: Router,
     private _transactionsService: TransactionsService) { }
   
  ngOnInit() {
    this.currentUser = this._authService.getUser();
    this.navService.sidenavOpen.subscribe(res => {
      this.sidenavOpen = res;
    });

    this._authService.currentEntity.subscribe(entite => {
      this.entite = entite;
    });
    this._entitesService.getRecentTransactions();
    this._entitesService.recentTransactions.subscribe( transactions => { this.transactions = transactions })

    this._authService.getCurrentUserEntite(this.currentUser.codeClient)
    .subscribe(res => {
      const entite = res[Constantes.HYDRA_MEMBER].length > 0 ? res[Constantes.HYDRA_MEMBER][0] : {}
      this._authService.changeEntite(new Entite(entite));
      if(entite.couleur) { this.setSideBarColor(entite.couleur) 
        this.setBackround(entite);
      }
    });
  }

  setSideBarColor(color){
    const body = document.getElementsByTagName('body')[0];
    body.style.setProperty('--entite-color', color);
  }
  setBackround(entite){
    console.log(entite.codeEntite);
    const body = document.getElementsByTagName('body')[0];

  }

  
  signout() {
    this._authService.signout();
  }
  profil() {
    this.router.navigateByUrl('/profil');
  }
}
