import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { HttpClient} from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { User } from "../models/user";
import { BehaviorSubject } from 'rxjs';
import { Entite } from "../models/Entite";
import { Constantes } from "../constantes";
import { Comptes } from "../models/comptes";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  //Only for demo purpose
  authenticated = false;
  private compteSource = new BehaviorSubject([]);
  currentCompte = this.compteSource.asObservable();
  private entitySource = new BehaviorSubject(new Entite({}));
  currentEntity = this.entitySource.asObservable();

  constructor(private store: LocalStoreService, private router: Router, public http:HttpClient) {
    this.checkAuth();
  }

  changeEntite(entite:Entite){
    this.entitySource.next(entite);
  }

  checkAuth() {
    this.authenticated = this.store.getItem("gu_login_status");
  }

  getUser():User {
    return new User(this.store.getItem('gu_current_user'));
  }


  getCurrentUserEntite(codeEntite) {
    return this.http.get<Entite>(`${environment.apiAdminUrl}/entites?codeEntite=${codeEntite}`)
  }

  signin(credentials) {
    return this.http.post<any>(`${environment.apiAuthUrl}/login`, credentials);
  }
  savetoken(data){
    this.store.setItem("gu_jwt_token", data.token);
    this.store.setItem("gu_current_user",  new User(data.user));
    this.authenticated = true;
    this.store.setItem("gu_login_status", true);
  }
  saveLoggedUser(data) {

  }
  changeSoldeComte(){
    this.getDetailsUser().subscribe(result => {
      this.compteSource.next(result.comptes);
    });
  }



  signout() {
    this.authenticated = false;
    this.store.setItem("gu_login_status", false);
    this.store.setItem("gu_jwt_token", null);
    this.store.setItem("gu_current_user", false);
    this.store.clear();
   this.router.navigateByUrl("/sessions/signin")


  }
  fogotPwd(credentials) {

    return this.http.post<any>(environment.apiAuthUrl + Constantes.FOGOT_PASSWORD, credentials);
  }
  getJwtToken() {
    return this.store.getItem('gu_jwt_token');
  }
   updatePwd(credentials) {

    return this.http.put<any>(environment.apiAuthUrl + Constantes.UPDATE_PASSWORD, credentials);
  }
  getDetailsUser(){
    return this.http.get<any>(environment.walletApi +'/clients/details' );
  }
  create(data){
    return this.http.post<any[]>(environment.walletApi+ '/clients',data);
  }
  changerCodePinService(credentials) {
    return this.http.put<any>(environment.apiAuthUrl + '/users/pin/change', credentials);
  }
  changerMdpService(credentials) {
    return this.http.put<any>(environment.apiAuthUrl + '/users/password/change', credentials);
  }

  updateUser(user){
    return this.http.put<any>(environment.apiAuthUrl + '/users/'+user.id, user);
  }
  sendTicket(redentials) {
    return this.http.post(environment.walletApi + '/osticket', redentials);
  }
}
