export class Constantes {
  static HYDRA_MEMBER = 'hydra:member';
  static DGC_JWT_TOKEN = 'dgc_jwt_token';
  static DGC_CURRENT_USER = 'dgc_current_user';
  static LOGIN_STATUS = 'login_status';
  static DEVISE = 'XOF';
  static CODE_AIRTIME = 'airtime';
  static CODE_SERVICE_PAIEMENT = 'encaissement';
  static TRANSFERT_SERVICE_CODE = 'BGTRANSFERT';
  static WALLETCASHIN_SERVICE_CODE = 'BGWALLET_CASHIN';
  static WALLETCASHOUT_SERVICE_CODE = 'BGWALLET_CASHOUT';
  static AIRTIME_SERVICE_CODE = 'BGAIRTIME';
  static PAIEMENT_MASSE ='BGMULTIPAY';
  static ACHAT_SERVICE_CODE = 'BGACHAT';
  static BGENC = 'BGENC';
  static FRAIS_A_PART = 'frais à part';
  static UPDATE_PASSWORD = '/users/password/change';
  static FOGOT_PASSWORD = '/users/password/forget';
  static BGRECHARGE = 'BGRECHARGE';
  static BGABO = 'BGABO';
  static FLOTTE = 'flotte';
  static FORFAIT = 'forfaits';
  static VOIP = 'voip';
  static BRL = 'blr';

  
}
