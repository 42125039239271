import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  images={}
  constructor() { }

  ngOnInit() {
    this.images = [
      {nom:"/assets/images/photo-wide-4.jpg"},
     {nom:"/assets/images/photo-wide-3.jpg"},
     {nom:"/assets/images/photo-wide-2.jpg"}
    ]
  }

  slickInit(e) {
    
  }
 
  
  afterChange(e) {
  }

}
