import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { User } from '../models/user';
import { BehaviorSubject } from 'rxjs';
import { TransactionsService } from './transactions.service';
import { Constantes } from '../constantes';

@Injectable({
  providedIn: 'root'
})
export class EntitesService {
  currentUser: User;
  private recentTransactionsSource = new BehaviorSubject([]);
  private serviceSource = new BehaviorSubject(null);
  currentService = this.serviceSource.asObservable();
  recentTransactions = this.recentTransactionsSource.asObservable();

  constructor(private http: HttpClient,
              private _authService: AuthService,
              private _transactionsService: TransactionsService) {
              this.currentUser = this._authService.getUser();

  }
  changeService(service) {
    this.serviceSource.next(service);
  }

  getRecentTransactions() {
    this._transactionsService.getRecentTransactions(this.currentUser.caisse)
        .subscribe(res => {
          this.recentTransactionsSource.next(res[Constantes.HYDRA_MEMBER]);
        })
  }

  addLastTransaction(transaction) {
    let transactions = this.recentTransactionsSource.value;
    const lastIndex = transactions.length - 1;
    if (lastIndex == 9) {
      transactions.splice(lastIndex, 1);
      transactions.unshift(transaction);
    }
    this.serviceSource.next(transactions);
  }

  abonnements() {
    return this.http.get<any>(`${environment.apiAdminUrl}/entities/mesabonnements`)
  }

  operateurs() {
    return this.http.get<any>(`${environment.apiAdminUrl}/operateurs?codeEntite=${this.currentUser.codeClient}`)
  }

  getOperateur(id) {
    return this.http.get<any>(`${environment.apiAdminUrl}/operateurs/${id}`)
  }

  caisseUser() {
    return this.http.get<any>(`${environment.apiReseauUrl}/caisses?pointDeVente=${this.currentUser.agence}&code=${this.currentUser.caisse}`)
  }

  getSoldeCaisse() {
    return this.http.get<any>(`${environment.apiReseauUrl}/caisse/solde`)
  }

  getComptes() {
    return this.http.get<any>(`${environment.walletApi}/mescomptes`)
  }

  deleteCompte(id: any) {
    return this.http.put(`${environment.walletApi}/comptes/status/${id}`, {status: '0'});
  }

}
