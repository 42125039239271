import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EntitesService } from './entites.service';
import { AuthService } from './auth.service';
import { Constantes } from '../constantes';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {
  currentEntite;
  constructor(private http: HttpClient) {
  
  }
  // Filtre d'envoi 
  filterEnvoi(values){
   
   let  urlpath = '?true=1'; 
   if(values.filtre && values.filtre.length > 0)
   {
     values.filtre.forEach((item : any) =>{
       if(item.filtre =='codeOperateur') {
         urlpath += '&operateur.codeOperateur=' + item.id; 
       }
       else if(item.filtre =='numTransaction') {
         urlpath += '&numTransaction=' + item.id; 
       }
       else if(item.filtre =='operation') {
        urlpath += '&status=' + item.id; 
      }
       // console.log('url', urlpath);
     }) ;
   }
   else{
     urlpath = "";
   }
   return urlpath;
 }

  filterTransaction(values){
   let  urlpath = '?_page=1'; 
   if(values.filtre && values.filtre.length > 0)
   {
     values.filtre.forEach((item : any) =>{
      if(item.filtre =='service') {
         urlpath += '&service.code=' + item.id; 
       }
       else if(item.filtre =='operateur') {
        urlpath += '&partenaire.code=' + item.id; 
      }
      else if(item.filtre =='numTransaction') {
        urlpath += '&numTransaction=' + item.id; 
      }
      
      else if(item.filtre =='operation') {
        urlpath += '&operation=' + item.id; 
      }
     }) ;

   }
   else{
     urlpath = "";
   }
   return urlpath;
 }
  getAllTransactions(values) {
    return this.http.get<any>(`${environment.apiReseauUrl}/transactions/entite/jour${this.filterTransaction(values)}`)
  }

  getService() {
    return this.http.get<any>(`${environment.apiReseauUrl}/services`);
  }

  getRecentTransactions(codeCaisse) {
    return this.http.get<any>(`${environment.walletApi}/transactions/clients/lastfour`); 
  }
  getoperateur(code)
  {
    
    return this.http.get<any>(`${environment.apiAdminUrl}/operateurs?codeEntite=${code}`)

  }
  getbilanCaisse()
  {
    return this.http.get<any>(`${environment.apiReseauUrl}/transactions/jour/bilan`)

  }
  getTransactionJour()
  {
    return this.http.get<any>(`${environment.apiReseauUrl}/etatsjournalier/encaissement`)
  }
  //historique Envoi
  transactionEnvoi(data) {
    return this.http.get<any>(environment.apiTransfertUrl +'/transactions/entite/jour'+this.filterEnvoi(data) )
  }

exportTransfert(data,type) {
  return this.http.get(environment.apiTransfertUrl  + '/transactions/entite/jour/'+type+ this.filterEnvoi(data), {
    observe: 'response',
    responseType: 'blob'
  });
}

}
