// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const BASE_API = 'https://services.pdg-dev.com';
export const environment = {
  allowAll:false,
  production: false,
  apiAuthUrl: 'https://auth-services.nt-albey.ne/api',
  apiAdminUrl: 'https://admin-services.nt-albey.ne/api',
  apiReseauUrl: 'https://reseau-services.nt-albey.ne/api',
  apiTransfertUrl: 'https://transfert-services.nt-albey.ne/api',
  walletApi: 'https://wallet-services.nt-albey.ne/api',
  apiAuthUrlMobile: 'https://auth-services.nt-albey.ne/api',
  //BASE_API+'mobile_auth/api'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
